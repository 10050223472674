class window.popups
  constructor: ->
    vex.defaultOptions.className = 'vex-theme-default'

    $('a.hash-popup').on 'click', (event) =>
      event.preventDefault()
      event.stopPropagation()
      href = $(event.currentTarget).attr('href').substr(1)
      if href.substr(0,1) == '_'
        @show(href.substr(1))
        return false

      if _.isFunction @[href]
        @[href]()
        return false

      return false

    $(window).on 'resize scroll', (event) =>
      clearTimeout @resizeTimeout
      @resizeTimeout = setTimeout () =>
        @_resize()
      , 250
    @_resize()

    $(document).on 'mouseup', (event) =>
      $('.popup:visible').each (index, popup) =>
        if !$(popup).is(event.target) and $(popup).has(event.target).length == 0
          @hide()

    $('.popup__close').on 'click', (event) =>
      @hide()

  how: ->
    window.how.show() if window.how

  show: (id) ->
    $('.popup').hide()
    $(".popup#popup--#{id}").show()
    $('#popup--splash').show()
    $("header .header__auth a.button[href=#_#{id}]").css 'zIndex', 200
    $('body').addClass 'popup--opened'

    @_resize()

  hide: (popup) ->
    $('.popup').hide()
    $('body').removeClass 'popup--opened'

    $('#popup--splash').hide()

  worklist: ->
    vex.open
      content: $('#popup--worklist').clone()

  _resize: ->
    if $(window).width() <= 720 and $(window).height() > 480
      $('.popup:visible .popup__content').height $(window).height() - $('header').height()
    else
      $('.popup .popup__content').css('height', 'auto')

    height = $('body').outerHeight() + $('footer').outerHeight()
    contentHeight = $(window).height() - $('header').outerHeight() - $('footer').outerHeight()
    mainHeight = $('main').outerHeight()  + $('#how-section:visible').height()

    if mainHeight < contentHeight
      $('footer').addClass 'footer--stick'
      $('main').addClass 'main--stick'
      $('body').height contentHeight
    else
      $('footer').removeClass 'footer--stick'
      $('main').removeClass 'main--stick'
      $('body').css('height', 'auto')

$ ->
  window.popupsRouter = new popups()